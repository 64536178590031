import { createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  class: "card border-0",
  style: {"width":"100%"},
  "data-aos": "fade-left"
}
const _hoisted_2 = { class: "card-header border-0" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "svg-icon svg-icon-1 position-absolute ms-2" }
const _hoisted_5 = { class: "border border-primary bg-light rounded-2" }
const _hoisted_6 = { class: "card-toolbar" }
const _hoisted_7 = { class: "d-flex ms-4 justify-content-en" }
const _hoisted_8 = {
  key: 0,
  class: "d-flex ms-4 justify-content-en"
}
const _hoisted_9 = {
  class: "modal fade",
  tabindex: "-1",
  id: "kt_modal_3",
  ref: "closeHexModal"
}
const _hoisted_10 = { class: "modal-dialog" }
const _hoisted_11 = { class: "modal-content roundeda" }
const _hoisted_12 = { class: "modal-body p-15" }
const _hoisted_13 = { class: "row align-middle" }
const _hoisted_14 = ["disabled"]
const _hoisted_15 = { class: "row my-3" }
const _hoisted_16 = ["disabled"]
const _hoisted_17 = ["value", "selected"]
const _hoisted_18 = { class: "row align-middle" }
const _hoisted_19 = ["disabled"]
const _hoisted_20 = { class: "row align-middle" }
const _hoisted_21 = ["disabled"]
const _hoisted_22 = { class: "row align-middle" }
const _hoisted_23 = ["disabled"]
const _hoisted_24 = { class: "row align-middle" }
const _hoisted_25 = ["disabled"]
const _hoisted_26 = ["value", "selected"]
const _hoisted_27 = { class: "row align-middle" }
const _hoisted_28 = ["disabled"]
const _hoisted_29 = ["value", "selected"]
const _hoisted_30 = { class: "row align-middle" }
const _hoisted_31 = {
  key: 0,
  class: "modal-footer"
}
const _hoisted_32 = {
  class: "modal fade",
  tabindex: "-1",
  id: "kt_modal_4",
  ref: "closeHexModal"
}
const _hoisted_33 = ["title"]
const _hoisted_34 = { key: 1 }
const _hoisted_35 = {
  key: 0,
  title: "True",
  class: /*@__PURE__*/_normalizeClass(`badge badge-light-success`),
  style: {"margin-left":"1.3rem"}
}
const _hoisted_36 = {
  key: 1,
  class: /*@__PURE__*/_normalizeClass(`badge badge-light-success ms-3`),
  title: "False"
}
const _hoisted_37 = {
  key: 0,
  title: "True",
  class: /*@__PURE__*/_normalizeClass(`badge badge-light-success`),
  style: {"margin-left":"1.3rem"}
}
const _hoisted_38 = {
  key: 1,
  class: /*@__PURE__*/_normalizeClass(`badge badge-light-success ms-3`),
  title: "False"
}
const _hoisted_39 = ["onClick"]
const _hoisted_40 = ["onClick"]
const _hoisted_41 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_Vue3JsonEditor = _resolveComponent("Vue3JsonEditor")!
  const _component_KTDatatable = _resolveComponent("KTDatatable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createCommentVNode("begin::Card header"),
      _createElementVNode("div", _hoisted_2, [
        _createCommentVNode("begin::Card title"),
        _createElementVNode("div", _hoisted_3, [
          _createCommentVNode(" v-model=\"search\"\r\n              @input=\"searchItems()\" "),
          _createElementVNode("span", _hoisted_4, [
            _createVNode(_component_inline_svg, { src: "/media/icons/duotune/general/gen021.svg" })
          ]),
          _createElementVNode("span", _hoisted_5, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
              onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.searchItems())),
              type: "text",
              "data-kt-subscription-table-filter": "search",
              class: "form-control form-control-solid w-250px ps-5",
              placeholder: "Search Webhooks",
              style: {"padding-left":"2.5rem !important"}
            }, null, 544 /* NEED_HYDRATION, NEED_PATCH */), [
              [_vModelText, _ctx.search]
            ])
          ])
        ]),
        _createCommentVNode("begin::Card title"),
        _createCommentVNode("begin::Card toolbar"),
        _createElementVNode("div", _hoisted_6, [
          _createCommentVNode("begin::Toolbar"),
          _cache[13] || (_cache[13] = _createElementVNode("div", { class: "d-flex justify-content-end" }, [
            _createCommentVNode("Modal data "),
            _createCommentVNode("Modal data end  ")
          ], -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("button", {
              class: "btn btn-primary",
              "data-bs-toggle": "modal",
              "data-bs-target": "#kt_modal_4",
              onClick: _cache[2] || (_cache[2] = ($event: any) => {
              {
                _ctx.edit = false;
                _ctx.view = false;
              }
            })
            }, " Look Up ")
          ]),
          (_ctx.permissions.includes('add-webhook'))
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createElementVNode("button", {
                  class: "btn btn-primary",
                  "data-bs-toggle": "modal",
                  "data-bs-target": "#kt_modal_3",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => {
              {
                _ctx.edit = false;
                _ctx.view = false;
              }
            })
                }, " Add Webhook ")
              ]))
            : _createCommentVNode("v-if", true)
        ]),
        _createCommentVNode("end::Card toolbar")
      ]),
      _createCommentVNode("end::Card header")
    ]),
    _createCommentVNode("Modal code Add Webhook start "),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _cache[25] || (_cache[25] = _createElementVNode("div", { class: "modal-header bg-primary roundeda" }, [
            _createElementVNode("span", { class: "modal-title text-white h5 text-uppercase ms-3" }, "Add Webhook"),
            _createCommentVNode("begin::Close"),
            _createElementVNode("div", {
              class: "btn btn-icon btn-sm",
              "data-bs-dismiss": "modal",
              "aria-label": "Close",
              id: "webhooks-close-modal",
              style: {"border-radius":"50px"}
            }, [
              _createElementVNode("i", {
                class: "bi bi-x-circle-fill light-text fs-3",
                style: {"color":"white"}
              })
            ]),
            _createCommentVNode("end::Close")
          ], -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _cache[14] || (_cache[14] = _createElementVNode("span", { class: "text-primary fs-4 font-weight-bold" }, "Name:", -1 /* HOISTED */)),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.payload.name) = $event)),
                class: "form-control my-3",
                placeholder: "Enter Name",
                disabled: _ctx.view
              }, null, 8 /* PROPS */, _hoisted_14), [
                [_vModelText, _ctx.payload.name]
              ])
            ]),
            _createElementVNode("div", _hoisted_15, [
              _cache[16] || (_cache[16] = _createElementVNode("span", { class: "text-primary fs-4 font-weight-bold" }, "Select Company:", -1 /* HOISTED */)),
              _createElementVNode("select", {
                class: "form-select form-select-solid border",
                "data-control": "select2",
                onChange: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.onCustomerChange && _ctx.onCustomerChange(...args))),
                "data-dropdown-parent": "#kt_modal_1",
                "data-placeholder": "Select an option",
                "data-allow-clear": "true",
                disabled: _ctx.view
              }, [
                _cache[15] || (_cache[15] = _createElementVNode("option", { value: "" }, "All", -1 /* HOISTED */)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customers, (customer, i) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: i,
                    value: customer.id,
                    selected: customer.id == _ctx.payload.se_account_id
                  }, _toDisplayString(customer.name + " (ID: " + customer.id + ")"), 9 /* TEXT, PROPS */, _hoisted_17))
                }), 128 /* KEYED_FRAGMENT */))
              ], 40 /* PROPS, NEED_HYDRATION */, _hoisted_16)
            ]),
            _createElementVNode("div", _hoisted_18, [
              _cache[17] || (_cache[17] = _createElementVNode("span", { class: "text-primary fs-4 font-weight-bold" }, "HTTPS Endpoint:", -1 /* HOISTED */)),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.payload.url) = $event)),
                class: "form-control my-3",
                placeholder: "HTTPS Endpoint",
                disabled: _ctx.view
              }, null, 8 /* PROPS */, _hoisted_19), [
                [_vModelText, _ctx.payload.url]
              ])
            ]),
            _createElementVNode("div", _hoisted_20, [
              _cache[18] || (_cache[18] = _createElementVNode("span", { class: "text-primary fs-4 font-weight-bold" }, "API KEY:", -1 /* HOISTED */)),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.payload.api_key) = $event)),
                class: "form-control my-3",
                placeholder: "API KEY",
                disabled: _ctx.view
              }, null, 8 /* PROPS */, _hoisted_21), [
                [_vModelText, _ctx.payload.api_key]
              ])
            ]),
            _createElementVNode("div", _hoisted_22, [
              _cache[20] || (_cache[20] = _createElementVNode("span", { class: "text-primary fs-4 font-weight-bold" }, "API KEY Header:", -1 /* HOISTED */)),
              _withDirectives(_createElementVNode("select", {
                class: "form-select form-select-solid border",
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.payload.api_key_header) = $event)),
                "data-control": "select-api_key_header",
                "data-dropdown-parent": "#kt_modal_1",
                "data-placeholder": "Select an option",
                "data-allow-clear": "true",
                disabled: _ctx.view
              }, _cache[19] || (_cache[19] = [
                _createElementVNode("option", { value: "default" }, "Default", -1 /* HOISTED */),
                _createElementVNode("option", { value: "device" }, "Device", -1 /* HOISTED */)
              ]), 8 /* PROPS */, _hoisted_23), [
                [_vModelSelect, _ctx.payload.api_key_header]
              ])
            ]),
            _createElementVNode("div", _hoisted_24, [
              _cache[21] || (_cache[21] = _createElementVNode("span", { class: "text-primary fs-4 font-weight-bold" }, "Decoder:", -1 /* HOISTED */)),
              _withDirectives(_createElementVNode("select", {
                class: "form-select form-select-solid border",
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.payload.is_decoded) = $event)),
                "data-control": "select-decoder",
                "data-dropdown-parent": "#kt_modal_1",
                "data-placeholder": "Select an option",
                "data-allow-clear": "true",
                disabled: _ctx.view
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.webhooks_decoder, (hook, i) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: i,
                    value: hook.value,
                    selected: hook.value == _ctx.payload.is_decoded
                  }, _toDisplayString(hook.name), 9 /* TEXT, PROPS */, _hoisted_26))
                }), 128 /* KEYED_FRAGMENT */))
              ], 8 /* PROPS */, _hoisted_25), [
                [_vModelSelect, _ctx.payload.is_decoded]
              ])
            ]),
            _createElementVNode("div", _hoisted_27, [
              _cache[22] || (_cache[22] = _createElementVNode("span", { class: "text-primary fs-4 font-weight-bold" }, "Enable Custom Payload:", -1 /* HOISTED */)),
              _withDirectives(_createElementVNode("select", {
                class: "form-select form-select-solid border",
                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.payload.is_custom_payload) = $event)),
                "data-control": "select-decoder",
                "data-dropdown-parent": "#kt_modal_1",
                "data-placeholder": "Select an option",
                "data-allow-clear": "true",
                disabled: _ctx.view
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.webhooks_decoder, (hook, i) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: i,
                    value: hook.value,
                    selected: hook.value == _ctx.payload.is_custom_payload
                  }, _toDisplayString(hook.name), 9 /* TEXT, PROPS */, _hoisted_29))
                }), 128 /* KEYED_FRAGMENT */))
              ], 8 /* PROPS */, _hoisted_28), [
                [_vModelSelect, _ctx.payload.is_custom_payload]
              ])
            ]),
            _createElementVNode("div", _hoisted_30, [
              _cache[23] || (_cache[23] = _createElementVNode("span", { class: "text-primary fs-4 font-weight-bold" }, "Custom Payload:", -1 /* HOISTED */)),
              _createVNode(_component_Vue3JsonEditor, {
                modelValue: _ctx.payload.payload,
                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.payload.payload) = $event)),
                "show-btns": false,
                expandedOnStart: true,
                mode: 'code',
                onJsonChange: _ctx.onJsonChange,
                disabled: _ctx.view
              }, null, 8 /* PROPS */, ["modelValue", "onJsonChange", "disabled"])
            ])
          ]),
          (!_ctx.view)
            ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                _cache[24] || (_cache[24] = _createElementVNode("button", {
                  type: "button",
                  class: "btn btn-light",
                  "data-bs-dismiss": "modal"
                }, " Close ", -1 /* HOISTED */)),
                _createElementVNode("button", {
                  type: "button",
                  onClick: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.saveDownlink && _ctx.saveDownlink(...args))),
                  class: "btn btn-primary"
                }, " Save changes ")
              ]))
            : _createCommentVNode("v-if", true)
        ])
      ])
    ], 512 /* NEED_PATCH */),
    _createCommentVNode("Modal code Add Webhook end "),
    _createCommentVNode("Modal code Look up Webhook start "),
    _createElementVNode("div", _hoisted_32, _cache[26] || (_cache[26] = [
      _createStaticVNode("<div class=\"modal-dialog\"><div class=\"modal-content roundeda\"><div class=\"modal-header bg-primary roundeda\"><span class=\"modal-title text-white h5 text-uppercase ms-3\">Available Data Fields</span><!--begin::Close--><div class=\"btn btn-icon btn-sm\" data-bs-dismiss=\"modal\" aria-label=\"Close\" id=\"webhooks-close-modal\" style=\"border-radius:50px;\"><i class=\"bi bi-x-circle-fill light-text fs-3\" style=\"color:white;\"></i></div><!--end::Close--></div><div class=\"modal-body p-15\"><table class=\"table lookup\"><thead><tr><th scope=\"col\">Key</th><th scope=\"col\">Details</th></tr></thead><tbody><tr><td>{data}</td><td>Contain Payload</td></tr><tr><td>{time}</td><td>Uplink time</td></tr><tr><td>{X_Axis}</td><td>Device orientation</td></tr><tr><td>{Y_Axis}</td><td>Device orientation</td></tr><tr><td>{Z_Axis}</td><td>Device orientation</td></tr><tr><td>{device}</td><td>Contain Device Id</td></tr><tr><td>{uplink}</td><td>Contain Uplink Type</td></tr><tr><td>{battery}</td><td>Contain Battery value in Percentage (%)</td></tr><tr><td>{Distance}</td><td>Raw Distance</td></tr><tr><td>{fireAlarm}</td><td>Contain value of fireAlarm Enable/Disable</td></tr><tr><td>{pickupType}</td><td>Contain value of pickupType</td></tr><tr><td>{pickupEvent}</td><td>Contain value of pickupEvent</td></tr><tr><td>{tamperEvent}</td><td>If Device Position is changed</td></tr><tr><td>{temperature}</td><td>Temperature of Bin</td></tr><tr><td>{seqNumber}</td><td>Sequence Number</td></tr></tbody></table></div></div></div>", 1)
    ]), 512 /* NEED_PATCH */),
    _createCommentVNode("Modal code Look up Webhook end "),
    _createCommentVNode("data table"),
    _createVNode(_component_KTDatatable, {
      style: {"margin-left":"30px !important"},
      "table-data": _ctx.data,
      total: _ctx.total,
      "table-header": _ctx.headerConfig,
      "enable-items-per-page-dropdown": true,
      loading: _ctx.loading,
      rowsPerPage: _ctx.pagination.page_size,
      onCurrentChange: _ctx.currentChange,
      onItemsPerPageChange: _ctx.itemsPerPageChange
    }, {
      "cell-name": _withCtx(({ row: data }) => [
        _createTextVNode(_toDisplayString(data.name), 1 /* TEXT */)
      ]),
      "cell-customer_name": _withCtx(({ row: data }) => [
        _createTextVNode(_toDisplayString(data.customer_name || "N/A"), 1 /* TEXT */)
      ]),
      "cell-url": _withCtx(({ row: data }) => [
        _createCommentVNode(" {{ data.url && data.url.length > 35 ? data.url.slice(0, 35) + '...' : data.url }} "),
        (data.url && data.url.length > 35)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              title: data.url
            }, _toDisplayString(data.url.slice(0, 35) + "..."), 9 /* TEXT, PROPS */, _hoisted_33))
          : (_openBlock(), _createElementBlock("span", _hoisted_34, _toDisplayString(data.url), 1 /* TEXT */))
      ]),
      "cell-api_key": _withCtx(({ row: data }) => [
        _createTextVNode(_toDisplayString(data.api_key), 1 /* TEXT */)
      ]),
      "cell-is_decoded": _withCtx(({ row: data }) => [
        (data.is_decoded == 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_35, _cache[27] || (_cache[27] = [
              _createElementVNode("i", { class: "fas fa-check text-primary" }, null, -1 /* HOISTED */)
            ])))
          : (_openBlock(), _createElementBlock("div", _hoisted_36, _cache[28] || (_cache[28] = [
              _createElementVNode("i", { class: "bi bi-x-circle text-danger" }, null, -1 /* HOISTED */)
            ])))
      ]),
      "cell-is_custom_payload": _withCtx(({ row: data }) => [
        (data.is_custom_payload == 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_37, _cache[29] || (_cache[29] = [
              _createElementVNode("i", { class: "fas fa-check text-primary" }, null, -1 /* HOISTED */)
            ])))
          : (_openBlock(), _createElementBlock("div", _hoisted_38, _cache[30] || (_cache[30] = [
              _createElementVNode("i", { class: "bi bi-x-circle text-danger" }, null, -1 /* HOISTED */)
            ])))
      ]),
      "cell-action": _withCtx(({ row: data }) => [
        _createTextVNode(_toDisplayString(data.action) + " ", 1 /* TEXT */),
        _createElementVNode("div", {
          class: _normalizeClass(`badge badge-light-success pointer`),
          "data-bs-toggle": "modal",
          "data-bs-target": "#kt_modal_3",
          onClick: ($event: any) => (_ctx.viewWebhook(data))
        }, _cache[31] || (_cache[31] = [
          _createElementVNode("i", { class: "far fa-eye text-primary" }, null, -1 /* HOISTED */)
        ]), 8 /* PROPS */, _hoisted_39),
        (_ctx.permissions.includes('edit-webhook'))
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(`badge badge-light-success pointer ms-3`),
              "data-bs-toggle": "modal",
              "data-bs-target": "#kt_modal_3",
              onClick: ($event: any) => (_ctx.editWebhook(data))
            }, _cache[32] || (_cache[32] = [
              _createElementVNode("i", { class: "fas fa-edit text-success" }, null, -1 /* HOISTED */)
            ]), 8 /* PROPS */, _hoisted_40))
          : _createCommentVNode("v-if", true),
        (_ctx.permissions.includes('delete-webhook'))
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(`badge badge-light-success ms-3 pointer`),
              onClick: ($event: any) => (_ctx.deleteHook(data.id))
            }, _cache[33] || (_cache[33] = [
              _createElementVNode("i", { class: "fas fa-trash text-danger" }, null, -1 /* HOISTED */)
            ]), 8 /* PROPS */, _hoisted_41))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["table-data", "total", "table-header", "loading", "rowsPerPage", "onCurrentChange", "onItemsPerPageChange"]),
    _createCommentVNode("modal code")
  ], 64 /* STABLE_FRAGMENT */))
}
import axios from "axios";
import URLS from "../urls";

export const getWebhooks = async (payload) => {
  console.log("check the paylaod ***",payload);
  const url = URLS.GET_WEBHOOKS;
  return await axios.post(url, payload).then((response) => response.data);
};

export const createWebhook = async (payload) => {
  const url = URLS.CREATE_WEBHOOKS;
  return await axios.post(url, payload).then((response) => response.data);
};

export const deleteWebhook = async (id) => {
  const url = URLS.DELETE_WEBHOOKS + id;
  return await axios.delete(url).then((response) => response.data);
};

export const updateWebhook = async (id, payload) => {
  const url = URLS.UPDATE_WEBHOOKS + id;
  return await axios.put(url, payload).then((response) => response.data);
};
